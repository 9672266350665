import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import axios from 'axios';

// Define a User type if you need to store user information
interface User {
  email: string;
}

interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  authenticateUser: () => void;
  logoutUser: () => void;
  currentUser: User | null;
  setCurrentUser: (user: User) => void;
}

const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const authenticateUser = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get('/api/validate-token');
      if (response.status === 200) {
        setIsAuthenticated(true);
        setCurrentUser({ email: response.data.userId });
      } else {
        setIsAuthenticated(false);
        setCurrentUser(null);
      }
    } catch (error) {
      setIsAuthenticated(false);
      setCurrentUser(null);
    } finally {
      setIsLoading(false);
    }
  };

  const logoutUser = async () => {
    setIsAuthenticated(false);
    await axios.post('/api/logout'); // Endpoint to clear the token cookie
    setCurrentUser(null);
  };

  useEffect(() => {
    authenticateUser();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading, authenticateUser, logoutUser, currentUser, setCurrentUser }}>
      {children}
    </AuthContext.Provider>
  );
};


export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
