import validator from 'validator';
import React, { useState, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import './LoginPage.css';
import LoginGitHub from '../../components/LoginGitHub'
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../components/CentralForm.css'

const LoginForm: React.FC = () => {
  const [username, setUsername] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const preAuthRoute = queryParams.get('preAuthRoute');
    if (preAuthRoute) {
      sessionStorage.setItem('preAuthRoute', preAuthRoute);
    }
  }, [location]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
    if (errorMessage) setErrorMessage('');
  };

  const handleLogin = async () => {
    if (!validator.isEmail(username)) {
      setErrorMessage('Please enter a valid email address');
      return;
    }

    setErrorMessage('');
    navigate(`/signin?email=${username}`);
  };

  return (
    <div className="overlay">
      <div className="central-form-container">
        <>
          <Typography variant="h5">Login Required</Typography>
          <TextField
            label="Email Address"
            type="email"
            autoComplete="email"
            fullWidth
            margin="normal"
            value={username}
            onChange={handleEmailChange}
            error={!!errorMessage}
            helperText={errorMessage}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleLogin();
              }
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogin}
            fullWidth
          >
            Login with Email
          </Button>
          <Box mt={2}>
            <Typography variant="body2">
              Don't have an account?{' '}
              <Link href="/signup">Sign Up</Link>
            </Typography>
          </Box>
          <Box mt={2} mb={2}>
            <Divider>
              <Chip label="OR" />
            </Divider>
          </Box>
          <LoginGitHub/>
        </>
      </div>
    </div>
  );

};

export default LoginForm;
