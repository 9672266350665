import React from 'react';
import { Box, Divider, Grid, Typography, Link } from '@mui/material';
import './Footer.css';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box className="footer" sx={{ bgcolor: '#f5f5f5', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ flexGrow: 1, py: 5, px: 3 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" gutterBottom>
              Contact
            </Typography>
            <Typography variant="body1">contact@advanced-engineering-analysis.com</Typography>
            <Typography variant="body1">+1 (303) 564-5096</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" gutterBottom>
              Company
            </Typography>
            <Link href="/about-us" underline="hover" variant="body1" color="inherit" sx={{ display: 'block', mb: 1 }}>
              About Us
            </Link>
            <Link href="/request-demo" underline="hover" variant="body1" color="inherit" sx={{ display: 'block', mb: 1 }}>
              Request a Demo
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" gutterBottom>
              Legal
            </Typography>
            <Link href="/sla" underline="hover" variant="body1" color="inherit" sx={{ display: 'block', mb: 1 }}>
              SLA (Software License Agreement)
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box sx={{ py: 2, px: 3, textAlign: 'left' }}>
        <Typography variant="body1">© {currentYear} Advanced Engineering Analysis LLC</Typography>
      </Box>
    </Box>
  );
};

export default Footer;
