import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { TextField, Button, Typography, Box, Snackbar } from '@mui/material';
import '../components/CentralForm.css'

const ContactUsForm = () => {
  const form = useRef<HTMLFormElement>(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();

    if (!process.env.REACT_APP_EMAILJS_PUBLIC_KEY) {
      console.warn('Warning: REACT_APP_EMAILJS_PUBLIC_KEY is not defined. Emails cannot be sent.');
      setSnackbarMessage('Email service is currently unavailable.');
      setSnackbarSeverity('warning');
      setOpenSnackbar(true);
      return;
    }

    if (!form.current) {
      setSnackbarMessage('Form is not properly initialized.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    emailjs
      .sendForm('service_5r5x28i', 'template_v1omw5o', form.current, 'PwatXPVPEbYM-XiGT')
      .then(
        (_) => {
          setSnackbarMessage('Email sent successfully!');
          setSnackbarSeverity('success');
          setOpenSnackbar(true);
        },
        (error) => {
          console.error('Failed to send the email:', error);
          setSnackbarMessage('Failed to send the email. Please try again later.');
          setSnackbarSeverity('error');
          setOpenSnackbar(true);
        },
      );
  };


  return (
    <div className='central-form-container'>
      <section>
        <Box sx={{ maxWidth: 400, mx: 'auto' }}>
          <Typography variant="h4">Contact Us</Typography>
          <Typography>Get in touch with us.</Typography>
          <form ref={form} onSubmit={sendEmail}>
            <TextField
              label="Name"
              fullWidth
              margin="normal"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              name="from_name"
            />
            <TextField
              label="Email"
              type="email"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              name="reply_to"
            />
            <TextField
              label="Message"
              multiline
              rows={4}
              fullWidth
              margin="normal"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              name="message"
            />
            <Box mt={2}>
              <Button variant="contained" color="primary" type="submit" fullWidth>
                Send
              </Button>
            </Box>
          </form>
        </Box>
      </section>
    <Snackbar
      open={openSnackbar}
      autoHideDuration={6000}
      onClose={() => setOpenSnackbar(false)}
      message={snackbarMessage}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      ContentProps={{
        sx: {
          backgroundColor: snackbarSeverity === 'success' ? 'green' : snackbarSeverity === 'error' ? 'red' : 'orange',
          color: '#fff',
        },
      }}
    />
    </div>
  );
};

export default ContactUsForm;
