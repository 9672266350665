import { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

export default function FeatureSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const features = [
    {
      title: 'Transform Your Models',
      description:
        'Transform your finite element meshes to perfectly match target surfaces from CAD, structured light scans, or other FEMs, ensuring precision and consistency.',
      image: '/features/thumbnails/morphing.webp',
    },
    {
      title: 'Support for Cyclic Features',
      description:
        'Efficiently handle full rotors or individual sectors, empowering accurate simulations for critical rotating machinery.',
      image: '/features/thumbnails/rotor_scan.webp',
    },
    {
      title: 'Boolean Operations',
      description:
        'Perform complex geometric edits like cutting holes, notches, and other features directly into mesh surfaces with unparalleled accuracy.',
      image: '/features/thumbnails/after_cut.webp',
    },
    {
      title: 'Scripting',
      description:
        'Record workflows and generate reusable, standalone scripts to automate repetitive tasks and streamline your processes.',
      image: '/features/thumbnails/scripting.webp',
    },
    {
      title: 'CAD Integrations',
      description:
        'Import and morph meshes to STEP and IGES models seamlessly, bridging the gap between CAD design and analysis workflows.',
      image: '/features/thumbnails/nist-cad.webp',
    },
    {
      title: 'Robust Automated Alignment',
      description:
        'Achieve fully automated and highly accurate part registration using full or partial feature recognition, ensuring optimal alignment in any workflow.',
      image: '/features/thumbnails/align_example_initial.webp',
    },
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? features.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === features.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        px: 8,
        py: 10,
        bgcolor: 'background.default',
        minHeight: '500px',
      }}
    >

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '50px',
          background: 'linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0))',
          zIndex: 1,
          pointerEvents: 'none',
        }}
      />

      <Box
        sx={{
          display: 'flex',
          transform: `translateX(-${currentIndex * 100}%)`,
          transition: 'transform 0.5s ease',
        }}
      >
        {features.map((feature, index) => (
          <Box
            key={index}
            sx={{
              px: 3,
              flex: '0 0 100%',
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              component="img"
              src={feature.image}
              alt={feature.title}
              sx={{
                width: { xs: '100%', md: '600px' },
                height: 'auto',
                borderRadius: 2,
                boxShadow: 3,
              }}
            />
            <Box
              sx={{
                width: { xs: '100%', md: '60%' },
                textAlign: { xs: 'center', md: 'left' },
                px: { xs: 0, md: 5 },
              }}
            >
              <Typography variant="h4" sx={{ mb: 3 }}>
                {feature.title}
              </Typography>
              <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                {feature.description}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>


      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          height: '100%',
          width: '100px',
          background: 'linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0))',
          zIndex: 1,
          pointerEvents: 'none',
        }}
      />

      <IconButton
        onClick={handlePrev}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '10px',
          transform: 'translateY(-50%)',
          zIndex: 2,
          bgcolor: 'background.paper',
          '&:hover': { bgcolor: 'primary.main', color: 'white' },
        }}
      >
        <ArrowBack />
      </IconButton>

      <IconButton
        onClick={handleNext}
        sx={{
          position: 'absolute',
          top: '50%',
          right: '10px',
          transform: 'translateY(-50%)',
          zIndex: 2,
          bgcolor: 'background.paper',
          '&:hover': { bgcolor: 'primary.main', color: 'white' },
        }}
      >
        <ArrowForward />
      </IconButton>
    </Box>

  );
}
