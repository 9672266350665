import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';

interface CaseStudy {
  image: string;
  title: string;
  summary: React.ReactNode;
  link: string;
  companyLink: string;
}

const caseStudies: CaseStudy[] = [
  {
    image: '/assets/afrl_logo.webp',
    title: 'Key Research and Analysis Software',
summary: (
  <>
    In active use by the Air Force Research Laboratory (AFRL) for research and analysis, particularly at the Turbine Engine Fatigue Facility (
    <a
      href="https://www.afrl.af.mil/Technology/TEFF/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none', color: '#3f51b5' }}
    >
      TEFF
    </a>
    ).
  </>
),
    link: '/case-studies/afrl',
    companyLink: 'https://www.afrl.af.mil/',
  },
  {
    image: '/assets/pratt_whitney_logo.webp',
    title: 'State of the Art Quality Control',
    summary:
      'First DoD commercial license of software under Section 801. FEMORPH provides advanced modeling and simulation capabilities to assess irregularities in turbine blades, resulting in cost savings and improved readiness.',
    link: '/case-studies/pratt-whitney',
    companyLink: 'https://www.prattwhitney.com/',
  },
  {
    image: '/assets/bdc_logo.svg',
    title: 'IBR Sustainment',
    summary:
      "Deployed in support of IBR sustainment by the Air Force Life Cycle Management Center (AFLCMC). Software used in conjunction with BDC's advanced techniques to predict IBR blade mode shapes due to changes in geometry caused by blade blending in production workflows at Tinker Air Force Base.",
    link: '/case-studies/bdc',
    companyLink: 'https://bladediagnostics.com/',
  },
];

const CaseStudies: React.FC = () => {
  return (
    <Box mt={8} mb={8} px={2}>
      <Typography variant="h3" align="center">
        Case Studies
      </Typography>
      <Typography variant="subtitle1" align="center">
        Explore how FEMORPH has been applied across the aerospace industry.
      </Typography>
      <Grid container spacing={4} mt={2}>
        {caseStudies.map((caseStudy, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', boxShadow: 3}}>
              <a
                href={caseStudy.companyLink}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <CardMedia
                  component="img"
                  image={caseStudy.image}
                  alt={caseStudy.title}
                  sx={{
                    mt: 2,
                    height: 140,
                    objectFit: 'contain',
                    padding: '15px',
                  }}
                />
              </a>
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="div">
                  {caseStudy.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {caseStudy.summary}
                </Typography>
              </CardContent>
              <Box textAlign="center" mb={2}>
                <Link
                  to={caseStudy.link}
                  style={{
                    textDecoration: 'none',
                    color: '#3f51b5',
                    fontWeight: 'bold',
                  }}
                >
                  Learn more
                </Link>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box mt={4} textAlign="center">
      </Box>
    </Box>
  );
};

export default CaseStudies;
