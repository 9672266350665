import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'
import '../components/CentralForm.css'

const SignIn: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>(null);
  const [userErrorMessage, setUserErrorMessage] = useState<React.ReactNode>(null);
  const { authenticateUser } = useAuth();

  const navigate = useNavigate()
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setEmail(queryParams.get('email') || '');
  }, [location]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (userErrorMessage) setUserErrorMessage('');
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (errorMessage) setErrorMessage('');
  };

  const handleSignIn = async () => {
    if (password.length < 1) {
      setErrorMessage('Please enter your password.');
      return;
    }

    try {
      const response = await axios.post('/api/signin', { email, password });
      if (response.status === 200) {
        authenticateUser();
        // Retrieve the stored path and redirect
        const preAuthRoute = sessionStorage.getItem('preAuthRoute') || '/';

        // need special treatment since /docs is served by node
        if (preAuthRoute.startsWith('/docs')) {
          window.location.href = preAuthRoute;
        } else {
          navigate(preAuthRoute);
        }

        sessionStorage.removeItem('preAuthRoute'); // Clear the stored route
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Handle specific error cases here
        console.error('Sign In error:', error);
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          setUserErrorMessage(
            <>User does not exist. <Link to="/signup">Sign Up</Link></>
          )
        } else if (axios.isAxiosError(error) && error.response?.status === 403) {
          setErrorMessage(
            <>Email not verified. Check your inbox for the activation email or <Link to="/reset-password-request">Reset Password</Link></>
          )
        } else if (axios.isAxiosError(error) && error.response?.status === 401) {
          setErrorMessage(
            <>Incorrect password. <Link to="/reset-password-request">Reset Password</Link></>
          )
        } else {
          setErrorMessage(`Failed to sign in: $(error)`)
        }
      }
    }
  };

  return (
    <div className="overlay">
      <div className='central-form-container'>
        <Typography variant="h4">Sign In</Typography>
        &nbsp;
        <TextField
          label="Email Address"
          type="username"
          autoComplete="username"
          fullWidth
          margin="normal"
          value={email}
          onChange={handleEmailChange}
          error={!!userErrorMessage}
          helperText={userErrorMessage}
        />
        <TextField
          label="Password"
          type="password"
          autoComplete="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={handlePasswordChange}
          error={!!errorMessage}
          helperText={errorMessage}
        />
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSignIn}
            fullWidth
          >
            Sign In
          </Button>
        </Box>
        <Typography mt={2}>
          Don't have an account? <Link to="/signup">Sign Up</Link>
        </Typography>
        <Typography mt={2}>
          Forgot your password? <Link to="/reset-password-request">Reset Password</Link>
        </Typography>

      </div>
    </div>
  );
};

export default SignIn;
